import TableContext from '../index'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import useScreen from 'utils/screen'
import { breakpoint } from 'utils/consts/breakpoints'
import randomString from 'random-string'
import { HEADER_CLASSNAME, TRANSITION_DURATION } from '../../CollapsibleHeader'

type Container = keyof typeof breakpoint
type ContainersMap = Container[]
type ElementToHide = HTMLElement & {
  __initialHeight: number
  __containerOnly: ContainersMap
  __uniqueId: string
}
export type RefCallbackOptions = {
  containerOnly?: ContainersMap
}

let timeoutId
let lastScrollTop = 0
let isHeadersVisible = true
let isAnimated = false
const GAP = 50

const mutationHandler = (mutations: MutationRecord[]) => {
  mutations.forEach(mutation => {
    const header: ElementToHide | null = (
      mutation.target as ElementToHide
    ).closest(`.${HEADER_CLASSNAME}`)
    if (!header) return
    header.style.maxHeight = `${header.scrollHeight}px`
    header.__initialHeight = header.scrollHeight
  })
}
const mutationObserver = new MutationObserver(mutationHandler)
const startObserve = (elements: ElementToHide[]) => {
  timeoutId = setTimeout(() => {
    elements.forEach(node => {
      mutationObserver.observe(node, { childList: true, subtree: true })
    })
  }, 1000)
}
const transitionEndHandler = (e: TransitionEvent) => {
  const target = e.target as HTMLElement
  if (target) {
    target.style.overflow = `visible`
  }
}

const TableContextProvider = ({ children }) => {
  const [reactTableProps, setReactTableProps] = useState()
  const [tableContentRef, _setTableContentRef] = useState<HTMLElement | null>(
    null
  )
  const [elementsToHide, _setCollapsibleHeader] = useState<ElementToHide[]>([])
  // @ts-ignore
  const { currentContainer }: { currentContainer: Container } = useScreen()

  const setTableContentRef = useCallback(node => {
    if (node !== null) {
      _setTableContentRef(node)
    }
  }, [])
  const setCollapsibleHeader = useCallback(
    (_node: HTMLElement | null, options: RefCallbackOptions = {}) => {
      const node = _node as ElementToHide
      if (node === null) return
      if (
        node.__uniqueId &&
        elementsToHide.some(el => el.__uniqueId === node.__uniqueId)
      ) {
        return
      }

      if (options.containerOnly) {
        node.__containerOnly = options.containerOnly
      }
      node.__uniqueId = randomString({ length: 10 })

      _setCollapsibleHeader(prev => [...prev, node as ElementToHide])
    },
    [elementsToHide]
  )

  const handleTableScroll = e => {
    const scrollTop = e.currentTarget.scrollTop
    const elements = elementsToHide.filter(node => {
      return (
        !node.__containerOnly || node.__containerOnly.includes(currentContainer)
      )
    })
    const heightToStarAnimation = elements.reduce((acc, el) => {
      return acc + el.__initialHeight
    }, GAP)
    const isScrollDown = scrollTop > lastScrollTop

    mutationObserver.disconnect()
    clearTimeout(timeoutId)

    if (isScrollDown) {
      if (heightToStarAnimation > scrollTop || !isHeadersVisible)
        return startObserve(elementsToHide)
      isHeadersVisible = false
      isAnimated = true
      elements.forEach(node => {
        const newHeight = Math.max(node.__initialHeight - scrollTop, 0)
        node.style.maxHeight = `${newHeight}px`
        node.style.overflow = `hidden`
        node.removeEventListener('transitionend', transitionEndHandler)
      })
      setTimeout(() => {
        isAnimated = false
      }, TRANSITION_DURATION)
    } else {
      if (heightToStarAnimation < scrollTop || isHeadersVisible || isAnimated)
        return startObserve(elementsToHide)
      isHeadersVisible = true
      elements.forEach(node => {
        node.style.maxHeight = `${node.__initialHeight}px`
        node.addEventListener('transitionend', transitionEndHandler, {
          once: true,
        })
      })
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop

    startObserve(elementsToHide)
  }

  useLayoutEffect(() => {
    elementsToHide.forEach(node => {
      node.style.maxHeight = 'none'
      node.__initialHeight = node.offsetHeight
    })
  }, [currentContainer])

  useEffect(() => {
    if (tableContentRef) {
      tableContentRef.onscroll = handleTableScroll
    }
  }, [tableContentRef, elementsToHide, currentContainer])

  useLayoutEffect(() => {
    elementsToHide.forEach(node => {
      node.__initialHeight = node.offsetHeight
      node.style.maxHeight = `${node.offsetHeight}px`
      mutationObserver.observe(node, { childList: true, subtree: true })
    })
  }, [elementsToHide])

  const value = useMemo(
    () => ({
      reactTableProps,
      setReactTableProps,
      setTableContentRef,
      tableContentRef,
      setCollapsibleHeader,
    }),
    [reactTableProps, setTableContentRef, setReactTableProps]
  )

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export default TableContextProvider
