import { FC, useState } from 'react'
import styled from 'styled-components'
import { setTokens } from 'utils/token'
import i18n from 'utils/i18n'
import { useTranslation } from 'react-i18next'
import useNotification from 'utils/notification'
import { SecondaryButtonFlexable48 } from 'components/Common/MasterComponents'
import { GoogleIcon } from 'components/Common/Icons'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google'
import BannedModal from '../BannedModal'
import {
  useSignInWithGoogleMutation,
  useSignInWithGoogleOneTapMutation,
} from 'api/hooks'

const IconWrapper = styled.div`
  display: flex;
  margin-right: 8px;
`
const ButtonText = styled.div`
  font: var(--typography-14-16-semibold);
  color: var(--color-night);
`

const GoogleSignInButton: FC<{ getMe: () => void }> = ({ getMe }) => {
  const { t } = useTranslation()
  const notify = useNotification()
  const [banModalVisible, setBanModalVisible] = useState(false)
  const handleSetTokens = ({ token, refreshToken, locale }) => {
    setTokens(token, refreshToken)
    localStorage.setItem('wwl-lng', locale || '')
    i18n.changeLanguage(locale).then(() => {
      getMe()
    })
  }

  const [signInWithGoogleOneTap] = useSignInWithGoogleOneTapMutation()

  const [signInWithGoogle] = useSignInWithGoogleMutation()

  const handleGoogleLoginSuccess = async tokenResponse => {
    const { code } = tokenResponse
    try {
      const { data, errors } = await signInWithGoogle({
        variables: {
          authCode: code,
        },
      })

      if (errors) {
        const errorCode = errors?.[0]?.extensions?.code
        if (errorCode === 'AUTH_BLOCKED_ERROR') {
          setBanModalVisible(true)
        }
      } else {
        const token = data?.signInWithGoogle?.token
        const refreshToken = data?.signInWithGoogle?.refreshToken
        const locale = data?.signInWithGoogle?.locale?.locale

        if (token && refreshToken) {
          handleSetTokens({ token, refreshToken, locale })
        }
      }
    } catch (error: any) {
      notify(error.message, 'error')
    }
  }

  const handleGoogleOneTapLoginSuccess = async credentialResponse => {
    const { credential } = credentialResponse
    try {
      const { data, errors } = await signInWithGoogleOneTap({
        variables: {
          idToken: credential,
        },
      })

      if (errors) {
        const errorCode = errors?.[0]?.extensions?.code
        if (errorCode === 'AUTH_BLOCKED_ERROR') {
          setBanModalVisible(true)
        }
      } else {
        const token = data?.signInWithGoogle?.token
        const refreshToken = data?.signInWithGoogle?.refreshToken
        const locale = data?.signInWithGoogle?.locale?.locale

        if (token && refreshToken) {
          handleSetTokens({ token, refreshToken, locale })
        }
      }
    } catch (error: any) {
      notify(error.message, 'error')
    }
  }

  useGoogleOneTapLogin({
    cancel_on_tap_outside: true,
    use_fedcm_for_prompt: true,
    onSuccess: handleGoogleOneTapLoginSuccess,
    onError: () => notify('Failed to use google one tap', 'error'),
  })

  const loginWithCode = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: handleGoogleLoginSuccess,
    ux_mode: 'popup',
    onError: () => notify('Failed to login with google', 'error'),
  })

  return (
    <>
      <SecondaryButtonFlexable48 onClick={() => loginWithCode()}>
        <IconWrapper>
          <GoogleIcon />
        </IconWrapper>
        <ButtonText>{t('login.withGoogle')}</ButtonText>
      </SecondaryButtonFlexable48>
      {banModalVisible && (
        <BannedModal closeModal={() => setBanModalVisible(false)} />
      )}
    </>
  )
}

export default GoogleSignInButton
